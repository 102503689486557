<template>
  <div class="c-app">
    <SideMenu
      :items="routes"
      :show="showMenu"
      @toggle-menu="handleToggleMenu"
    />
    <CWrapper>
      <Header @toggle-menu="handleToggleMenu" />
      <div class="c-body">
        <CAlert v-if="isHomolog" color="warning" class="text-center mb-0">
          <CIcon name="cil-warning" class="ml-2 mb-1" />
          <b>
            Você está utilizando o ambiente de homologação, quaisquer alterações
            realizadas não afetarão o sistema em produção.
          </b>
        </CAlert>
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
        </main>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import SideMenu from '@/components/ui/SideMenu'
import menuItems from '@/components/ui/SideMenu/menuItems'
import Header from '@/components/layout/Header'
import { mapGetters } from 'vuex'

export default {
  components: {
    SideMenu,
    Header
  },

  data() {
    return {
      routes: [],
      showMenu: false
    }
  },

  computed: { ...mapGetters('settings', ['isHomolog']) },

  created() {
    this.setRoutes()
    this.listenEventsOnRouteUpdate()
    this.openMenu()
    this.loadSettings()
  },

  methods: {
    loadSettings() {
      this.$store.dispatch('settings/load')
    },
    async setRoutes() {
      this.routes = menuItems.map((item) => {
        item._children = item._children.map((children) => {
          return children
        })

        return item
      })
    },

    listenEventsOnRouteUpdate() {
      // eslint-disable-next-line no-unused-vars
      this.$root.$on('update:routes', (data) => {
        this.setRoutes()
      })
    },

    openMenu() {
      const handler = () => {
        if (window.innerWidth > 992) {
          this.showMenu = true
        } else {
          this.showMenu = false
        }
      }

      window.addEventListener('resize', () => {
        handler()
      })

      handler()
    },

    handleToggleMenu() {
      this.showMenu = !this.showMenu
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
