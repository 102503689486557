// Para procurar por um novo icone, é necessário entrar no site abaixo e filtrar
// apenas pelo icones "free"
// https://icons.coreui.io/icons/
export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Banners',
        icon: 'cil-image',
        items: [
          { name: 'Home', to: '/banners-home' },
          { name: 'Peças', to: '/banners-part' },
          { name: 'Seguros', to: '/banners-insurance' },
          { name: 'Iveco Capital', to: '/banners-financing' },
          { name: 'Sobre Nós', to: '/banners-about' }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Serviços para agendamento',
        to: '/maintenance-services',
        icon: 'cil-calendar'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Assinatura',
        to: '/signature',
        icon: 'cil-contact',
        items: [
          { name: 'Assinaturas', to: '/signature' },
          { name: 'Cargos', to: '/signature-job' }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Unidades',
        to: '/unity',
        icon: 'cil-institution'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Veículos',
        icon: 'cil-car-alt',
        items: [
          { name: 'Modelos', to: '/models' },
          { name: 'Veículos', to: '/vehicles' },
          { name: 'Ícones', to: '/icons' }
        ]
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Consórcios',
        to: '/consortiums',
        icon: 'cil-money'
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Contatos',
        icon: 'cil-envelope-letter',
        items: [
          { name: 'Assuntos', to: '/contact-subjects' },
          { name: 'Contatos', to: '/contacts' }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Leads',
        icon: 'cil-paper-plane',
        items: [
          { name: 'Modelos', to: '/leads/models' },
          { name: 'Veículos', to: '/leads/vehicles' },
          { name: 'Peças', to: '/leads/parts' },
          { name: 'Consórcio', to: '/leads/consortiums' },
          { name: 'Plano de manutenções', to: '/leads/maintenance-plans' },
          { name: 'Agendamento', to: '/leads/review-schedules' },
          { name: 'Top Drivers', to: '/leads/top-drivers' },
          { name: 'Iveco Connect', to: '/leads/iveco-connect' },
          { name: 'Seguros', to: '/leads/insurances' },
          { name: 'Iveco Capital', to: '/leads/financing' }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Notícias',
        icon: 'cil-newspaper',
        items: [
          {
            name: 'Categorias',
            to: '/news-category'
          },
          {
            name: 'Notícias',
            to: '/news'
          }
        ]
      },
      {
        _name: 'CSidebarNavDropdown',
        name: 'Administrativo',
        icon: 'cil-lock-locked',
        items: [
          { name: 'Usuários', to: '/users' },
          { name: 'SEO', to: '/seo' },
          { name: 'Configurações', to: '/settings' },
          {
            name: 'Cookies',
            to: '/cookies',
            validation: ({ settings }) =>
              settings.cookies_notification_version == 'complete'
          }
        ]
      }

      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Trabalhe conosco',
      //   to: '/work-with-us',
      //   icon: 'cil-briefcase'
      // }
    ]
  }
]
